import { render, staticRenderFns } from "./LargeBaseCard.vue?vue&type=template&id=286c27c9&scoped=true&"
import script from "./LargeBaseCard.vue?vue&type=script&lang=js&"
export * from "./LargeBaseCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286c27c9",
  null
  
)

export default component.exports